import React from 'react'
import styles from './join-as-a-brand.module.scss'
import {Link} from 'gatsby'

const JoinAsABrandBanner = () => (
  <div className={`${styles.banner} py-12 sm:py-16`}>
    <div className='container mx-auto px-4 text-center'>
      <div className={`${styles.title} mb-3`}>
        Join as a Brand
      </div>
      <p className='mb-4'>
        Talk to one of our dedicated integration specialists today
      </p>
      <button className='bg-white hover:bg-gray-300 text-black font-bold text-sm uppercase py-2 px-8 rounded-sm my-3'>
        <Link to='/get-in-touch/'>Get in touch</Link>
      </button>
    </div>
  </div>
)

export default JoinAsABrandBanner
