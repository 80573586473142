import React from 'react'
import Layout from '../components/layout'
import JoinAsABrandBanner from '../components/join-as-a-brand'
import SEO from '../components/seo'
import styles from './brands.module.scss'
import IncrementalSalesImg from '../components/brands/incremental-sales'
import GetInFrontOfNewCustomers1Img from '../components/brands/get-in-front-of-new-customers-1'
import GetInFrontOfNewCustomers2Img from '../components/brands/get-in-front-of-new-customers-2'
import BuildLastingRelationships1Img from '../components/brands/build-lasting-relationships-1'
import BuildLastingRelationships2Img from '../components/brands/build-lasting-relationships-2'
import bigCommerceLogo from '../images/big-commerce.svg'
import bigCartelLogo from '../images/big-cartel.svg'
import shopifyLogo from '../images/shopify.svg'
import volusionLogo from '../images/volusion.svg'
import wooCommerceLogo from '../images/woo-commerce.svg'
import threedCartLogo from '../images/3d-cart.svg'

const BrandPage = () => (
  <Layout>
    <SEO title='Brand' />

    <div className='container mx-auto mb-12'>
      <div className={styles.hero}>
        <div className='pt-32 pb-16 sm:pb-16 md:pt-32 md:pb-24 lg:pt-56 lg:pb-32 xl:pt-64 xl:pb-40 px-4 lg:px-8'>
          <h1 className='w-4/5 lg:w-1/2 text-2xl sm:text-4xl'>
            Allow creator content to drive your sales
          </h1>
        </div>
      </div>

      <div className='flex flex-wrap mt-6'>
        <div className='w-1/2 lg:w-1/4 px-4 lg:text-center mt-3'>
          <div className='text-2xl sm:text-5xl font-black text-black my-3'>
            81%
          </div>
          <div className='text-sm'>
            of consumers purchasing decisions are influenced by their friends posts
          </div>
        </div>
        <div className='w-1/2 lg:w-1/4 px-4 lg:text-center mt-3'>
          <div className='text-2xl sm:text-5xl font-black text-black my-3'>
            80%
          </div>
          <div className='text-sm'>
            of people report that UGC increases products discoverability, improves brand trust and creates a more engaging experience
          </div>
        </div>
        <div className='w-1/2 lg:w-1/4 px-4 lg:text-center mt-3'>
          <div className='text-2xl sm:text-5xl font-black text-black my-3'>
            75%
          </div>
          <div className='text-sm'>
            of shoppers discover new products on social media
          </div>
        </div>
        <div className='w-1/2 lg:w-1/4 px-4 lg:text-center mt-3'>
          <div className='text-2xl sm:text-5xl font-black text-black my-3'>
            +19%
          </div>
          <div className='text-sm'>
            socially engaged shoppers spend 19% more on average
          </div>
        </div>
      </div>

      <div className='mt-24 md:mt-16 block flex flex-col sm:flex-row-reverse justify-evenly items-center'>
        <div className={`my-5 ${styles.img}`}>
          <IncrementalSalesImg />
        </div>
        <div className='px-4 sm:w-1/2'>
          <h2>Incremental sales, no upfront fees, remarkable commission rate</h2>
          <p className='mt-6 mb-8 md:my-2'>All products are free to list on our platform, only pay commission on items when they're sold</p>
        </div>
      </div>

      <div className='mt-24 md:mt-16'>
        <div className='px-4 text-center'>
          <h2>Your products integrated</h2>
          <p className='mt-6 mb-8 md:my-2'>Integrate into your current systems with MODE and start selling in minutes</p>
        </div>
        <div className='flex flex-wrap mt-6'>
          <div className='w-1/2 lg:w-1/6'>
            <img src={bigCommerceLogo} className='my-3 mx-auto' alt='Big Commerce' />
          </div>
          <div className='w-1/2 lg:w-1/6'>
            <img src={bigCartelLogo} className='my-3 mx-auto' alt='Big Commerce' />
          </div>
          <div className='w-1/2 lg:w-1/6'>
            <img src={shopifyLogo} className='my-3 mx-auto' alt='Big Commerce' />
          </div>
          <div className='w-1/2 lg:w-1/6'>
            <img src={volusionLogo} className='my-3 mx-auto' alt='Big Commerce' />
          </div>
          <div className='w-1/2 lg:w-1/6'>
            <img src={wooCommerceLogo} className='my-3 mx-auto' alt='Big Commerce' />
          </div>
          <div className='w-1/2 lg:w-1/6'>
            <img src={threedCartLogo} className='my-3 mx-auto' alt='Big Commerce' />
          </div>
        </div>
      </div>

      <div className='mt-24 md:mt-16 block flex flex-col sm:flex-row justify-evenly items-center'>
        <div className='px-4 sm:w-1/2'>
          <h2>Get in front of new customers</h2>
          <p className='mt-6 mb-8 md:my-2'>Increased brand discoverability for potential customers based on our advanced AI that matches their preferences to your items</p>
        </div>
        <div className={styles.twoImgs}>
          <div className={styles.img1}>
            <GetInFrontOfNewCustomers1Img />
          </div>
          <div className={styles.smallImg2}>
            <GetInFrontOfNewCustomers2Img />
          </div>
        </div>
      </div>

      <div className='mt-24 md:mt-16 mb-24 block flex flex-col sm:flex-row-reverse justify-evenly items-center'>
        <div className='px-4 sm:w-1/2'>
          <h2>Build lasting relationships</h2>
          <p className='mt-6 mb-8 md:my-2'>Create long-term partnerships with brand ambassadors and analyse campaign performance only through MODE</p>
        </div>
        <div className={styles.twoImgsReverse}>
          <div className={styles.img1}>
            <BuildLastingRelationships1Img />
          </div>
          <div className={styles.smallImg2}>
            <BuildLastingRelationships2Img />
          </div>
        </div>
      </div>
    </div>

    <JoinAsABrandBanner />
  </Layout>
)

export default BrandPage
